import BSPFinancialYearsDropDown from '../models/bsp_financial_years';
import BSPWeek from '../models/bsp_week';
import http_report from './http.report.service'

const apiEndpoint = '/report';

export async function getBSPFinancialYears() {
  const response = await http_report.get<BSPFinancialYearsDropDown[]>(
    apiEndpoint + '/GetBSPFinancialYearDropdown'
  );
  return response;
}

export async function GetLastBSPWeek() {
  const response = await http_report.get<BSPWeek>(
    apiEndpoint + '/GetLastBSPWeek'
  );
  return response.data;
}

export async function GetBSPWeek(weekNo, financialYear, isWeekIgnore) {
  const body = { weekNo, financialYear, isWeekIgnore }
  const response = await http_report.post<BSPWeek>(
    apiEndpoint + '/GetBSPWeek', body
  );
  return response.data;
}

export default {
  getBSPFinancialYears,
  GetLastBSPWeek,
  GetBSPWeek
};
