import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Switch, useTheme } from '@mui/material';

interface CustomToggleSwitchProps {
  name: string;
  label: string;
  defaultValue?: boolean;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  onChange?: (value: boolean) => void; // Custom onChange event
}

export const CustomToggleSwitch: React.FC<CustomToggleSwitchProps> = ({
  name,
  label,
  defaultValue = false,
  fontSize = 'lg',
  onChange: customOnChange, // Capture custom onChange prop
}) => {
  const theme = useTheme();

  const fontSizeStyles = {
    sm: {
      fontSize: theme.typography.body2.fontSize,
    },
    md: {
      fontSize: theme.typography.body1.fontSize,
    },
    lg: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    xl: {
      fontSize: theme.typography.h6.fontSize,
    },
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState }) => (
        <FormControlLabel
          control={
            <Switch
              name={name}
              color="primary"
              checked={value}
              onChange={(e) => {
                const checked = e.target.checked;
                onChange(checked); // Call react-hook-form's onChange
                if (customOnChange) {
                  customOnChange(checked); // Call custom onChange if provided
                }
              }}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.main, // Use primary color from theme
                },
                ...fontSizeStyles[fontSize], // Apply font size styles
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};
