import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import bspWeekService from '../services/bsp.week.service';
import BSPFinancialYearsDropDown from '../models/bsp_financial_years';

interface BSPFinancialYearsState {
  bspFinancialYears: BSPFinancialYearsDropDown[];
}

const initialState: BSPFinancialYearsState = {
  bspFinancialYears: []
};

const slice = createSlice({
  name: 'bspFinancialYears',
  initialState,
  reducers: {
    getBSPFinancialYearDropdown(
      state: BSPFinancialYearsState,
      action: PayloadAction<{ bspFinancialYears: BSPFinancialYearsDropDown[] }>
    ) {
      const { bspFinancialYears } = action.payload;

      state.bspFinancialYears = bspFinancialYears;
    }
  }
});

export const reducer = slice.reducer;

export const getBSPFinancialYearDropdown =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    let { data } = await bspWeekService.getBSPFinancialYears();

    const bspFinancialYears = Array.isArray(data) ? data : [];

    dispatch(slice.actions.getBSPFinancialYearDropdown({ bspFinancialYears }));
  };

export default slice;
