import ContactDetails from 'src/ems/models/contact_models/contact_details';
import ContactSummary from 'src/ems/models/contact_models/contact_summary';
import Reference from 'src/ems/models/reference';
import http from './http.service';
import AgentDetails from 'src/ems/models/agent_models/agent_details';

const apiEndpoint = '/contact';
const agentContactapiEndpoint = '/agentcontact';

export async function create(data: ContactDetails) {
  const response = await http.post(apiEndpoint + '/create', data);
  return response;
}

export async function update(data: ContactDetails) {
  await http.put(apiEndpoint + '/update', data);
}

export async function getContacts(signal?: any) {
  const response = await http.get<ContactSummary[]>(apiEndpoint + '/list', {
    signal: signal
  });
  return response;
}

export async function getContactsView(signal?: any) {
  const response = await http.get<ContactSummary[]>(apiEndpoint + '/listview', {
    signal: signal
  });
  return response;
}

export async function getAllContacts(signal?: any) {
  const response = await http.get<ContactSummary[]>(apiEndpoint + '/fulllist', {
    signal: signal
  });
  return response;
}

export async function getBusinessManagers(signal?: any) {
  const response = await http.get<Reference[]>(apiEndpoint + '/bmlist', {
    signal: signal
  });
  return response;
}

export async function getLandProductManagers(signal?: any) {
  const response = await http.get<Reference[]>(
    apiEndpoint + '/landproductmanagerlist',
    {
      signal: signal
    }
  );
  return response;
}

export async function getContactDetail(entityId: string) {
  const response = await http.get<ContactDetails>(
    apiEndpoint + '/detail/' + entityId
  );
  return response;
}

export async function getContactEntityLinkList(
  contactId: string
) {
  const response = await http.get<AgentDetails[]>(
    apiEndpoint + '/entitylinklist/' + contactId
  );
  return response;
}

export async function getContactEntityLinkByEntityId(
  entityId: string,
  signal?: any
) {
  const response = await http.get<AgentDetails[]>(
    apiEndpoint + '/entitylink/' + entityId,
    {
      signal: signal
    }
  );
  return response;
}

export async function checkExistingContact(contactId?: any, email?: any, email2?: any) {
  const body = {
    email: email,
    email2: email2,
    contactId: contactId
  };

  try {
    const response = await http.post(
      apiEndpoint + '/checkExistingContact',
      body
    );

    if (response.data.status === 'Ok' && response.data === true) {
      return true;
    } else {
      return response.data || 'Error checking existing name';
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data || 'Contact Exisit';
    } else {
      return false;
    }
  }
}

export const initialiseContact = (isCreate?): ContactDetails => {
  const contactDetails: ContactDetails = {
    contactId: '0',
    name: '',
    firstName: '',
    surname: '',
    abn: '',
    pcc: '',
    officeAddress: {
      line1: '',
      suburb: '',
      postcode: '',
      referenceCountryId: '21',
      referenceStateId: 13,
      telephone: '',
      mobile: '',
      email: ''
    },
    referenceJobType: '419',
    referenceSalutation: '86',
    /*   {
      referenceId: 51,
      refText: 'Street front',
      refType: 'SHO',
      displayText: null,
      value: 51,
      label: 'Street front',
      cssText: null,
      statusId: 1
    },
    referenceEntityClientTypeId: '66',
  
    supplierCode: '', */
    referenceStatusId: '1',
    isManagerFlag: false,
    receivesFinancialFlag: false,
    rewardAccountNumber: '',
    rewardCardNumber: '',
    rewardMemberSince: null,
    rewardCardStart: null,
    rewardCardExpiry: null,
    isCreate
  };

  return contactDetails;
};

export async function setContactPrimary(entityContactId) {
  const response = await http.post(
    agentContactapiEndpoint + '/setContactPrimary/' + entityContactId
  );
  return response;
}

export default {
  create,
  getContacts,
  getContactsView,
  getBusinessManagers,
  getLandProductManagers,
  getContactDetail,
  initialiseContact,
  update,
  getContactEntityLinkList,
  getContactEntityLinkByEntityId,
  checkExistingContact,
  setContactPrimary,
  getAllContacts
};
