import React, { useState, useEffect } from 'react';
import ReportFilterPanel from 'src/ems/components/common/ReportFilterPanel';
import ReportTicketingService from 'src/ems/services/report.ticketing.service';
import ReportParameters from 'src/ems/models/report_models/report_parameters';
import 'flexmonster/flexmonster.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSnackbar } from 'notistack';
import { getReportFilterPanel, getMapping } from './services/Service';
import { ReportFilterPanelProps } from 'src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps';
import { CustomFlexmonsterReport } from 'src/ems/components/common/Flexmonster/CustomFlexmonsterReport';
import { getPreference } from 'src/ems/services/contact.preference.service';
import { role } from 'src/ems/enums/roles.enums';
import { finance_details_report_columns, defaultFinanceDetailsReportColumns } from 'src/ems/models/agent_report_models/finance_details_report_columns';
import { downloadExcelFile } from 'src/ems/services/report.general.service';

export const FinanceDetails: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [isExpandPanel, setIsExpandPanel] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [mapping, setMapping] = useState(null);
  const [data, setData] = useState(null);
  const [title] = useState<string>("Finance Details Report");
  const [reportExportFileName, setReportExportFileName] = useState<string>("Finance Details Report");
  const [reportFilterPanelProps, setReportFilterPanelProps] = useState<ReportFilterPanelProps>(null);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [schedulerDetails, setSchedulerDetails] = useState(null);
  const [preferedColumns, setPreferedColumns] = useState<finance_details_report_columns>(defaultFinanceDetailsReportColumns());

  useEffect(() => {
    const fetchData = async () => {
      if (reportFilterPanelProps === null) {
        await getPreference(role.RPT_FINANCE_DETAILS, isDefault).then((response) => {
          const reportFilterPanelData = getReportFilterPanel(response.parameters);
          setReportFilterPanelProps(reportFilterPanelData);
          if (response.columns !== null) 
          {
            setPreferedColumns(response.columns)
          }
          if (response.scheduler !== null) 
          {
            setSchedulerDetails(response.scheduler)
          }
        });
      }
    };
  
    fetchData();
  }, [reportFilterPanelProps]);

  const customizeCell = (cell, data) => {
    if (data.measure && data.type !== 'header' && data.measure.uniqueName === "note") {
      cell.text = `<span class="tooltipNote" title="${data.label}">${data.label}</span>`;
    }
  }

  const handleExpansion = (event, value) => {
    setIsExpandPanel(value)
  }

  const handleReportSubmit = (data: ReportParameters) => {
    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);

    const parameters: ReportParameters = ReportTicketingService.getReportParameters(data);

    ReportTicketingService.getFinanceDetailsReport(parameters)
      .then((a) => {
        enqueueSnackbar('Report Loaded Successfully!!', {
          variant: 'success'
        });
        setData(a);
        setMapping(getMapping(a, preferedColumns))
        setReportExportFileName(title)
        setIsLoadingReport(false);
        setRefreshKey(prevKey => prevKey + 1);
      })
      .catch((error) => {
        console.log('Error -> ', error);
        enqueueSnackbar(error, {
          variant: 'error'
        });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };

  const handleQuickExport = async (data: ReportParameters) => {

    setData(null);
    setIsLoadingReport(true);
    setIsExpandPanel(false);
    
    const parameters: ReportParameters = ReportTicketingService.getReportParameters(data);
    let reportFileName = title;

    //Start downloadReport in the background
    ReportTicketingService.downloadReportEMS(parameters, null, null, null, role.RPT_FINANCE_DETAILS)
      .then((downloadResponse) => {
        downloadExcelFile(downloadResponse, reportFileName);
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      })
      .catch((error) => {
        console.error('Failed to download report:', error);
        enqueueSnackbar('Failed to download the report!', { variant: 'error' });
        setIsLoadingReport(false);
        setIsExpandPanel(true);
      });
  };

  return (
    <>
      {reportFilterPanelProps !== null && <div>
        <ReportFilterPanel
          onSubmit={handleReportSubmit}
          onQuickExport={handleQuickExport}
          reportTitle={title}
          isLoadingReport={isLoadingReport}
          isExpandPanel={isExpandPanel}
          reportFilters={reportFilterPanelProps}
          columnPrefrence={preferedColumns}
          schedulerPreference={schedulerDetails}
          handelExpansion={handleExpansion}
          isReportType={false}
          showFilterButtons={false}
        />
      </div>}
      {isLoadingReport ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px'
          }}
        >
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      ) : null}
      <div style={{ margin: '15px'}}>
        {data ? (
          <CustomFlexmonsterReport
            title={title} 
            data={data}
            refreshKey={refreshKey}
            mapping={mapping}
            fileName={reportExportFileName}
            reportType={'flat'}
            customizeCell={customizeCell}
          />
        ) : null}
      </div>
    </>
  );
}
