import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { format, parseISO, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';

interface CustomDatePickerProps {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: Date | string | null;
  minDate?: any;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  label,
  disabled = false,
  defaultValue = null,
  minDate = null,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();
  const [internalError, setInternalError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Watch the field value to ensure the component re-renders when the value changes
  const fieldValue = watch(name);

  // Helper function to parse date strings reliably
  const parseDateValue = (value: string | null): Date | null => {
    if (!value) return null;
    const parsedDate = parseISO(value);
    return isValid(parsedDate) ? parsedDate : null;
  };

  // Determine initial date value from defaultValue or form value
  const initialDate: Date | null = (() => {
    if (defaultValue) {
      const parsedDefault = parseDateValue(defaultValue as string);
      return parsedDefault;
    } else {
      const formValue = getValues(name);
      const parsedFormValue = formValue ? parseDateValue(formValue as string) : null;
      return parsedFormValue;
    }
  })();


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || null}
        render={({ field }) => {
          const parsedValue = field.value ? parseDateValue(field.value) : null;

          const renderDay = (
            day: Date,
            selectedDates: Array<Date | null>,
            pickersDayProps: PickersDayProps<Date>
          ) => {
            return (
              <PickersDay
                {...pickersDayProps}
                onMouseDown={() => {
                  // Update form state
                  field.onChange(format(day, "yyyy-MM-dd"));

                  // Close the DatePicker calendar
                  setIsOpen(false);
                }}
              />
            );
          };

          return (
            <DatePicker
              label={label}
              inputFormat="dd/MM/yyyy"
              minDate={minDate}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Select date..."
                  error={!!errors[name] || internalError}
                  helperText={
                    errors[name]
                      ? errors[name].message
                      : internalError
                      ? 'Invalid Date'
                      : ''
                  }
                />
              )}
              value={parsedValue}
              onChange={(date) => {
                if (date && isValid(date)) {
                  field.onChange(format(date, "yyyy-MM-dd"));
                  setInternalError(false);
                } else {
                  setInternalError(true);
                }
              }}
              renderDay={renderDay}
              open={isOpen}
              onOpen={() => {
                setIsOpen(true);
              }}
              onClose={() => {
                setIsOpen(false);
              }}
              onError={(error) => {
                setInternalError(error !== null);
              }}
              disableMaskedInput
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
