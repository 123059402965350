import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  const VersionManagement = Loader(
    lazy(() => import('src/ems/components/admin/version/VersionManagment'))
  );

  const ReferenceManagement = Loader(
    lazy(() => import('src/ems/components/admin/reference'))
  );
 
  const EmailBuilderTemplate= Loader(
    lazy(() => import('src/ems/components/admin/emailBuilderTemplate'))
  );

  const BSPWeekManage= Loader(
    lazy(() => import('src/ems/components/admin/bspWeekManage'))
  );
  
const adminRoutes = [
  {
    path: '',
    element: <Navigate to="admin" replace />
  },
  {
    path: 'version',
    element: <VersionManagement />
  },
  {
    path: 'reference',
    element: <ReferenceManagement />
  },
  {
    path: 'emailBuilderTemplate',
    element: <EmailBuilderTemplate />
  },
  {
    path: 'bspWeekManage',
    element: <BSPWeekManage />
  }
];

export default adminRoutes;


