import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import type ContactSummary from 'src/ems/models/contact_models/contact_summary';
import ContactService from 'src/ems/services/contact.service';

interface AllContactState {
  allContacts: ContactSummary[];
}

const initialState: AllContactState = {
  allContacts: []
};

const slice = createSlice({
  name: 'allContacts',
  initialState,
  reducers: {
    getAllContacts(
      state: AllContactState,
      action: PayloadAction<{ allContacts: ContactSummary[] }>
    ) {
      const { allContacts } = action.payload;

      state.allContacts = allContacts;
    }
  }
});

export const reducer = slice.reducer;

export const getAllContacts =
  (signal?: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      let { data } = await ContactService.getAllContacts(signal);

      const allContacts = Array.isArray(data) ? data : [];
      dispatch(slice.actions.getAllContacts({ allContacts }));
    } catch (error) {
      if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
        console.error(error);
      }
    }
  };

export default slice;
