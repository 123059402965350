import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import EDMSubscriberList from 'src/ems/components/reports/general/EDMSubscriberList';
import FinanceDetails from 'src/ems/components/reports/general/FinanceDetails';
import LandOverrides from 'src/ems/components/reports/general/LandOverrides';
import PrivateFares from 'src/ems/components/reports/general/PrivateFares';
import ProductSales from 'src/ems/components/reports/general/ProductSales';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AgencyByAirlineAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgencyByAirlineAnnual'
      )
  )
);

const AgencyByAirlineMonthly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgencyByAirlineMonthly'
      )
  )
);

const AgencyByAirlineCommissionAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgencyByAirlineCommissionAnnual'
      )
  )
);

const AgentsSalesExistingAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgentsSalesExistingAnnual'
      )
  )
);

const AgentsSalesExistingWeekly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgentsSalesExistingWeekly'
      )
  )
);

const AgentsNewBusiness = Loader(
  lazy(
    () =>
      import('src/ems/components/reports/ticketing/agency/AgentsNewBusiness')
  )
);

const AgencySalesSummaryAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgencySalesSummaryAnnual'
      )
  )
);

const AgencySalesSummaryMonthly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/agency/AgencySalesSummaryMonthly'
      )
  )
);

const AgentsTicketUsage = Loader(
  lazy(
    () =>
      import('src/ems/components/reports/ticketing/agency/AgentsTicketUsage')
  )
);

const AgentsVsBsp = Loader(
  lazy(() => import('src/ems/components/reports/ticketing/agency/AgentsVsBsp'))
);

const DetailedTicketReport = Loader(
  lazy(
    () =>
      import('src/ems/components/reports/ticketing/agency/DetailedTicketReport')
  )
);

const TicketSectorReport = Loader(
  lazy(
    () =>
      import('src/ems/components/reports/ticketing/agency/TicketSectorReport')
  )
);

const AirlineByAgencyAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/airline/AirlineByAgencyAnnual'
      )
  )
);

const AirlineByAgencyMonthly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/airline/AirlineByAgencyMonthly'
      )
  )
);

const AirlineSalesExistingWeekly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/airline/AirlineSalesExistingWeekly'
      )
  )
);

const AirlineSalesSummaryAnnual = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/airline/AirlineSalesSummaryAnnual'
      )
  )
);

const AirlineSalesSummaryMonthly = Loader(
  lazy(
    () =>
      import(
        'src/ems/components/reports/ticketing/airline/AirlineSalesSummaryMonthly'
      )
  )
);

const AgentExtended = Loader(
  lazy(() => import('src/ems/components/reports/general/AgentExtended'))
);

const AgentAuditTrail = Loader(
  lazy(() => import('src/ems/components/reports/general/AgentAuditTrail'))
);

const AgentNetOutstanding = Loader(
  lazy(() => import('src/ems/components/reports/general/AgentNetOutstanding'))
);

const PccList = Loader(
  lazy(() => import('src/ems/components/reports/general/PccList'))
);

const BdmWeekly = Loader(
  lazy(() => import('src/ems/components/reports/general/BdmWeekly'))
);

const SystemExtended = Loader(
  lazy(() => import('src/ems/components/reports/general/SystemExtended'))
);

const reportsRoutes = [
  {
    path: '',
    element: <Navigate to="ticketing" replace />
  },
  {
    path: 'ticketing/agency',
    children: [
      {
        path: 'agency-by-airline-monthly',
        element: <AgencyByAirlineMonthly />
      },
      {
        path: 'agency-by-airline-annual',
        element: <AgencyByAirlineAnnual />
      },
      {
        path: 'agency-by-airline-commission-annual',
        element: <AgencyByAirlineCommissionAnnual />
      },
      {
        path: 'agents-sales-existing-annual',
        element: <AgentsSalesExistingAnnual />
      },
      {
        path: 'agents-sales-existing-weekly',
        element: <AgentsSalesExistingWeekly />
      },
      {
        path: 'agents-new-business',
        element: <AgentsNewBusiness />
      },
      {
        path: 'agents-sales-summary-annual',
        element: <AgencySalesSummaryAnnual />
      },
      {
        path: 'agents-sales-summary-monthly',
        element: <AgencySalesSummaryMonthly />
      },
      {
        path: 'agents-tickets-usage',
        element: <AgentsTicketUsage />
      },
      {
        path: 'agents-vs-bsp',
        element: <AgentsVsBsp />
      },
      {
        path: 'detailed-ticket-report',
        element: <DetailedTicketReport />
      },
      {
        path: 'ticket-sector-report',
        element: <TicketSectorReport />
      }
    ]
  },
  {
    path: 'ticketing/airline',
    children: [
      {
        path: 'airline-by-agency-annual',
        element: <AirlineByAgencyAnnual />
      },
      {
        path: 'airline-by-agency-monthly',
        element: <AirlineByAgencyMonthly />
      },
      {
        path: 'airline-sales-existing-weekly',
        element: <AirlineSalesExistingWeekly />
      },
      {
        path: 'airline-sales-summary-annual',
        element: <AirlineSalesSummaryAnnual />
      },
      {
        path: 'airline-sales-summary-monthly',
        element: <AirlineSalesSummaryMonthly />
      }
    ]
  },
  ,
  {
    path: 'general',
    children: [
      {
        path: 'agent-extended',
        element: <AgentExtended />
      },
      {
        path: 'agent-audit-trail',
        element: <AgentAuditTrail />
      },
      {
        path: 'agent-net-outstanding',
        element: <AgentNetOutstanding />
      },
      {
        path: 'bdm-weekly',
        element: <BdmWeekly />
      },
      {
        path: 'pcc-list',
        element: <PccList />
      },
      {
        path: 'system-extended',
        element: <SystemExtended />
      },
      {
        path: 'product-sales',
        element: <ProductSales />
      },
      {
        path: 'land-overrides',
        element: <LandOverrides />
      },
      {
        path: 'edm-subscriber-list',
        element: <EDMSubscriberList />
      },
      {
        path: 'financial-details',
        element: <FinanceDetails />
      },
      {
        path: 'private-fares',
        element: <PrivateFares />
      }
    ]
  }
];

export default reportsRoutes;
