import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from 'src/ems/models/formInputProps';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { forwardRef } from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString // Corrected this property
        allowLeadingZeros // Allows leading zeros
        prefix=""
      />
    );
  },
);

const CheckLengthLimit = (e, numericLengthLimit, charLengthLimit) => {
  if (numericLengthLimit > 0) {
    const value = e.target.value;
    // Avoid parsing to integer to preserve leading zeros
    e.target.value = value.slice(0, numericLengthLimit);
  } else if (charLengthLimit > 0) {
    e.target.value = e.target.value.slice(0, charLengthLimit);
  }
};

const IsAllowedEmptySpace = (e, hasNoSpace) => {
  if (hasNoSpace) {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/\s+/g, '');
    e.target.value = filteredValue;
  }
};

export const Input = ({
  name,
  control,
  label,
  textCase,
  isFormatNumber = false,
  numericLengthLimit = 0,
  charLengthLimit = 0,
  hasNoSpace = false,
  placeholder = '', 
  ...rest
}: FormInputProps) => {
  const applyTextCase = (value: any) => {
    if (typeof value !== 'string') {
      return value;
    }
    switch (textCase) {
      case 'uppercase':
        return value.toUpperCase();
      case 'lowercase':
        return value.toLowerCase();
      case 'capitalize':
        return value.replace(/\b\w/g, (char) => char.toUpperCase());
      default:
        return value;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          {...rest}
          inputProps={{ style: { textTransform: textCase } }}
          InputProps={
            isFormatNumber
              ? {
                  inputComponent: NumericFormatCustom as any,
                }
              : null
          }
          helperText={error && typeof error.message === 'string' ? error.message : null}
          error={!!error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            CheckLengthLimit(e, numericLengthLimit, charLengthLimit);
            IsAllowedEmptySpace(e, hasNoSpace);
            let inputValue = applyTextCase(e.target.value);
            onChange(inputValue);
          }}
          value={applyTextCase(value || '')}
          fullWidth
          label={label}
          variant="outlined"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true, 
          }}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            CheckLengthLimit(e, numericLengthLimit, charLengthLimit);
            IsAllowedEmptySpace(e, hasNoSpace);
          }}
        />
      )}
    />
  );
};

Input.defaultProps = {
  textCase: 'uppercase',
};
