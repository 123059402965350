import EntitySummary from 'src/ems/models/entity_summary';
import http from './http.service';
import { getCurrentUser } from './auth.service';

const apiEndpoint = '/agent';

export async function getAgents(signal?: any) {
  const response = await http.get<EntitySummary[]>(apiEndpoint + '/list', {
    signal: signal
  });
  return response;
}

export async function getReportAgents(signal?: any) {
  const response = await http.get<EntitySummary[]>(apiEndpoint + '/reportlist', {
    signal: signal
  });
  return response;
}


export default {
  getAgents,
  getReportAgents
};