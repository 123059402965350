import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const List = Loader(lazy(() => import('src/ems/components/agents/List')));

const View = Loader(lazy(() => import('src/ems/components/agents/View')));

const Create = Loader(lazy(() => import('src/ems/components/agents/Create')));

const AgentGroup = Loader(lazy(() => import('src/ems/components/agents/AgentGroup')));

const AgentParentGroup = Loader(lazy(() => import('src/ems/components/agents/AgentParentGroup')));

const agentsRoutes = [
  {
    path: '',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',

    element: <List />
  },
  {
    path: 'view',
    children: [
      {
        path: '',
        element: <Navigate to="1" replace />
      },
      {
        path: ':entityId',
        element: <View />
      }
    ]
  },
  {
    path: 'Create',
    element: <Create />
  },
  {
    path: 'AgentGroup',
    element: <AgentGroup />
  },
  {
    path: 'AgentParentGroup',
    element: <AgentParentGroup />
  }
];

export default agentsRoutes;
