export interface land_overrides_report_columns {
  financialYear: boolean;
  agencyName: boolean;
  agencyGroup: boolean;
  headerGroup: boolean;
  supplierName: boolean;
  incentivePeriod: boolean;
  totalPay: boolean;
  tierValue: boolean;
  paymentPercent: boolean;
  paymentAmount: boolean;
  paidOnFlagDescription: boolean;
  tranParticulars: boolean;
}

export function defaultLandOverridesReportColumns(): land_overrides_report_columns {
  return {
    financialYear: true,
    agencyName: true,
    agencyGroup: true,
    headerGroup: true,
    supplierName: true,
    incentivePeriod: true,
    totalPay: true,
    tierValue: true,
    paymentPercent: true,
    paymentAmount: true,
    paidOnFlagDescription: true,
    tranParticulars: true
  };
}
