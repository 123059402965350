export enum role {
  //Agents
  VIEW_AGENT_LIST = 68,
  CREATE_NEW_AGENT = 69,
  EDIT_AGENT_DETAILS = 252,
  EDIT_TAB_AGT_FINANCE = 197,
  EDIT_ENTITY_TRAINING = 230,
  EDIT_AGENT_SALESNOTES = 237,
  EDIT_AGENT_USER_PAYS = 240,
  EDIT_AGENT_PRICING = 241,
  EDIT_AGENT_ADMIN = 242,
  EDIT_AGENT_SYSTEMS_DETAILS = 244,
  EDIT_AGENT_PRIVATE_FARES = 220,

  //Agent Group
  ADD_AGENT_GROUP = 319,
  VIEW_AGENT_GROUP = 320,
  DELETE_AGENT_GROUP = 321,
  EDIT_AGENT_GROUP = 322,

  //Network Parent Group
  ADD_ENTITYPARENT_GROUP = 327,
  EDIT_ENTITYPARENT_GROUP = 328,
  DELETE_ENTITYPARENT_GROUP = 329,
  VIEW_ENTITYPARENT_GROUP =	330,

  EDIT_SUPPLIER_DETAILS_TAB = 303,
  EDIT_TAB_SUPPLIER_ADMIN = 212,
  VIEW_CONTACT_LIST = 62,
  CREATE_NEW_CONTACT = 63,

  //Airline Offer
  CREATE_AIRLINE_OFFER = 311,
  VIEW_AIRLINE_OFFER = 59,
  EDIT_AIRLINE_OFFER = 60,
  DELETE_AIRLINE_OFFER = 305,

  //Airline BSP
  CREATE_AIRLINE_BSP = 308,
  VIEW_AIRLINE_BSP = 66,
  EDIT_AIRLINE_BSP = 310,
  DELETE_AIRLINE_BSP = 309,

  LAND_CONTRACT_SETTINGS = 302,

  //External Client Info
  TAB_EXTERNAL_CLIENT_INFO = 307,
  EDIT_EXTERNAL_CLIENT_INFO = 306,
  DELETE_EXTERNAL_CLIENT_INFO = 305,
  ADD_EXTERNAL_CLIENT_INFO = 304,

  //Franchise Agreement
  TAB_AGENT_FRANCHISE_AGREEMENT = 315,
  EDIT_AGENT_FRANCHISE_AGREEMENT = 314,
  
  //Product Land
  VIEW_PRODUCTSALES_DATA = 137,
  VIEW_PRODUCT_SALES_LANDOVERRIDES = 147,
  VIEW_LANDOVERRIDES_SUPPLIER_BREAKDOWN = 148,
  MARKINTENDTOPAY_LANDOVERRIDES_SUPPLIER = 151,
  CANCELINTENDTOPAY_LANDOVERRIDES_SUPPLIER = 152,
  MARKINTENDTOPAYADJUST_LANDOVERRIDES_SUPPLIER = 153,
  CANCELINTENDTOPAYADJUST_LANDOVERRIDES_SUPPLIER = 154,
  APPROVE_PAYMENT_LANDOVERRIDES = 155,
  REQUEST_APPROVAL_LANDOVERRIDES = 156,
  APPROVE_PAYMENT_ADJUST_LANDOVERRIDES = 157,
  REQUEST_APPROVAL_ADJUST_LANDOVERRIDES = 158,
  VIEW_CURRENT_QUARTER_PAYMENT_LANDOVERRIDES = 161,
  VIEW_HISTORY_CURRENT_QUARTER_BATCHPAYFILE_LANDOVERRIDES = 166,
  VIEW_HISTORY_CURRENT_QUARTER_BATCHBREAKDOWN_LANDOVERRIDES = 167,

  SEND_LAND_PAYMENT_REMITTANCES = 652,
  SEND_TRIAL_LAND_PAYMENT_REMITTANCES	= 653,
  VIEW_LAND_PAYMENT_REMITTANCES	= 295,
  VIEW_LAND_PAYMENT_REMITTANCES_HISTORY = 654,
  IT = 17,
  ETG_STAFF = 37,

  //LAND CONTRACTS
  BROWSE_LAND_CONTRACT = 168,

  // AGENCY REPORTS
  RPT_AGENCY_BY_AIRLINE_ANNUAL = 47,
  RPT_AGENCY_BY_AIRLINE_MONTHLY = 48,
  RPT_AGENCY_BY_AIRLINE_COMMISSION = 53,
  RPT_AGENTS_SALES_EXISTING_ANNUAL = 270,
  EDIT_FINANCE_BANK_TRUST_NAME = 102,
  EDIT_FINANCE_BANK_TRUST_BSB = 103,
  EDIT_FINANCE_BANK_TRUST_ACC = 104,
  EDIT_FINANCE_BANK_GENERAL_NAME = 105,
  EDIT_FINANCE_BANK_GENERAL_BSB = 106,
  EDIT_FINANCE_BANK_GENERAL_ACC = 107,
  EDIT_FINANCE_CREDIT_LIMIT = 194,

  // REPORT ROLES
  RPT_AGENTS_SALES_EXISTING_WEEKLY = 271,
  RPT_AGENTS_NEW_BUSINESS = 272,
  RPT_AGENCY_SALES_SUMMARY_ANNUAL = 56,
  RPT_AGENCY_SALES_SUMMARY_MONTHLY = 57,
  RPT_AGENT_VS_BSP = 211,
  RPT_AGENTS_TICKET_USAGE = 273,
  RPT_DETAILED_TICKET_REPORT = 274,
  RPT_TICKET_SECTOR = 54,

  //CONTACTS
  TAB_CONTACT_DETAILS = 91,
  TAB_CONTACT_ROLES = 95,
  TAB_CONTACT_SECURITY = 97,
  TAB_CONTACT_STATES = 96,
  TAB_CONTACT_CMS_SETTINGS = 226,
  EDIT_CONTACT_CMS_SETTINGS = 227,
  TAB_CONTACT_REFERENCES = 293,

  //LAND CONTRACTS
  LIST_LAND_CONTRACT = 248,
  CREATE_LAND_CONTRACT = 249,
  CLONE_LAND_CONTRACT = 169,
  EDIT_LAND_CONTRACT = 184,
  DELETE_LAND_CONTRACT = 183,

  // AIRLINE REPORTS
  RPT_AIRLINE_AGENCY_ANNUAL_SALES = 49,
  RPT_AIRLINE_AGENCY_MONTHLY_SALES = 50,
  RPT_AIRLINE_SALES_EXISTING_WEEKLY = 44,
  RPT_AIRLINE_SALES_SUMMARY_ANNUAL = 51,
  RPT_AIRLINE_SALES_SUMMARY_MONTHLY = 52,

  // GENERAL REPORTS
  RPT_AGENCY_EXTENDED = 61,
  RPT_BDM_WEEKLY = 55,
  RPT_AGENCY_SYSTEM_EXTENDED = 210,
  RPT_GENERAL_PRODUCT_SALES = 278,
  DSB_LAND_OVERRIDE = 39,
  RPT_EDM_SUBSCRIBER_LIST = 268,
  RPT_FINANCE_DETAILS = 651,
  RPT_PRIVATE_FARES = 283,
  RPT_AGENT_AUDIT_TRAIL = 290,
  RPT_AGENT_NET_OUTSTANDING = 291,
  RPT_DETAILED_PCC = 292,
  RPT_LAND_OVERRIDES = 300,

  //DASHBOARD
  DBS_MANAGEMENT = 275,
  DBS_SALES = 276,
  DBS_PRODUCT = 277,
  DSB_HIGHLIGHTS = 331,
  DSB_TURNAROUND = 332,

  //SUPPLIER TAB
  VIEW_SUPPLIER_LIST = 64,
  CREATE_NEW_SUPPLIER = 65,
  CREATE_SUPPLIER_PRIVATE_FARES = 245,
  DELETE_SUPPLIER_PRIVATE_FARES = 246,
  EDIT_SUPPLIER_PRIVATE_FARES = 221,
  TAB_SUPPLIER_PRIVATE_FARES = 218,
  TAB_SUPPLIER_SELF_TICKETING = 279,
  TAB_SUPPLIER_TRAINING = 282,
  CLONE_SUPPLIER_PRIVATE_FARES = 285,

  //AGENT TAB
  TAB_AGENT_TRAINING = 281,
  TAB_AGENT_PRIVATE_FARES = 219,
  TAB_AGT_DOCUMENTS = 82,
  TAB_AGENT_SYSTEMS = 243,
  TAB_AGENT_USER_PAYS = 80,
  TAB_AGENT_PRICING = 79,
  TAB_AGENT_ADMIN = 76,
  TAB_AGENT_FINANCE_DETAILS = 253,
  TAB_AGT_CONTACTS = 74,
  TAB_AGT_DETAILS = 73,
  TAB_AGT_SALES = 78,



  //Dashboard Widget
  DSB_WIDGET_SALES_DASHBOARD = 280,

  //Admin Version
  ADMIN_VERSION = 286,
  ADMIN_VERSION_ADD = 287,

  //Reference Management
  VIEW_REFERENCE_LIST = 203,
  CREATE_REFERENCE = 257,
  EDIT_REFERENCE = 258,
  DELETE_REFERENCE = 259,

  //Auth
  USER_BYPASS = 288,

  // Default Preference
  SET_DEFAULT_PREFERENCE = 289,
  RPT_SET_SCHEDULER = 301,

  //Email Builder Template
  VIEW_EMAIL_TEMPLATE_LIST = 296,
  CREATE_EMAIL_TEMPLATE = 297,
  EDIT_EMAIL_TEMPLATE = 298,
  DELETE_EMAIL_TEMPLATE = 299,

  //Land Contract
  TAB_LAND_CONTRACT_DETAILS = 110,
  TAB_LAND_CONTRACT_NETWORKS = 111,
  TAB_LAND_CONTRACT_PRODUCTS = 112,
  TAB_LAND_CONTRACT_MNGMTFEE = 113,
  TAB_LAND_CONTRACT_INCGRWTH = 114,
  TAB_LAND_CONTRACT_EXREWARDS = 115,
  TAB_LAND_CONTRACT_MARKETING = 116,
  TAB_LAND_CONTRACT_DOCUMENTS = 117
}
