import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { useFormContext } from 'react-hook-form';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getBusinessManagers } from 'src/ems/slices/businessManager';
import { getReportAgents } from 'src/ems/slices/reportAgent';
import { getSuppliers } from 'src/ems/slices/supplier';
import { getProducts } from 'src/ems/slices/supplierProducts';

import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import Dropdown from 'src/ems/components/rhf-components/Dropdown';
import CustomCheckbox from 'src/ems/components/rhf-components/Checkbox';
import CustomRadioGroup from 'src/ems/components/rhf-components/CustomRadioGroup';

import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import { DashboardOptionPanelProps } from '../Models/DashboardFilterPanelProps';
import { StatusEnum, SupplierTypeEnum } from 'src/ems/enums/general.enums';
import { getCurrentUser } from 'src/ems/services/auth.service';


export const DashboardOptionPanel: React.FC<DashboardOptionPanelProps> = ({
  title = 'Dashboard Filters',
  agent,
  businessManager,
  state,
  network,
  subGroup,
  headerGroup,
  product,
  supplier,
  groupByType,
  showActiveSupplierCheck = false,
  showLandAndAirOption = false,
  supplierType = SupplierTypeEnum.AIRLINE
}) => {
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();

  const { references } = useSelector((store) => store.referenceDropDown);
  const { businessManagers } = useSelector((store) => store.businessManager);
  const { suppliers } = useSelector((store) => store.supplier);
  const { supplierProducts } = useSelector((store) => store.supplierProducts);
  const { agents } = useSelector((store) => store.reportAgent);

  const [isShowActiveSupplier, setIsShowActiveSupplier] = useState<boolean>(true);
  const [selectedSupplierType, setSelectedSupplierType] =
    useState<number>(supplierType);

  const userInfo = getCurrentUser();
  const headerGroupIds = userInfo.reportOnlyHeaderGroups;
  const groupIds = userInfo.reportOnlyGroups;
  const subGroupIds = userInfo.reportOnlySubGroups;
  const stateIds = userInfo.reportOnlyStateOrProvince;


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getSuppliers(signal));
    dispatch(getBusinessManagers(signal));
    dispatch(getReportAgents(signal));
    dispatch(getProducts(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const headerGroupOptions = useMemo(() => {
    return references
      .filter(
        (ref) =>
          headerGroupIds.includes(ref.value.toString()) &&
          ref.type === 'HEADER_GRP'
      )
      .map((ref) => ({ label: ref.label, value: ref.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [references, headerGroupIds]);

  const allNetworkOptions = useMemo(() => {
    return references
      .filter(
        (ref) =>
          ref.type === 'GRP' && groupIds.includes(ref.value.toString())
      )
      .map((ref) => ({ label: ref.label, value: ref.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [references, groupIds]);

  const allSubGroupOptions = useMemo(() => {
    return references
      .filter((ref) => ref.type === 'SUB_GRP' && subGroupIds.includes(ref.value.toString()))
      .map((ref) => ({ label: ref.label, value: ref.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [references, subGroupIds]);

  const allStateOptions = useMemo(() => {
    return references
      .filter(
        (ref) =>
          stateIds.includes(ref.value.toString()) && ref.type === 'STA'
      )
      .map((ref) => ({ label: ref.label, value: Number(ref.value) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [references, agents]);

  const allBusinessManagerOptions = useMemo(() => {
    const distinctBMIds = Array.from(
      new Set(
        agents
          .map((obj) => obj.bmContactId)
          .filter((id) => id !== null && id !== 0)
      )
    );
    return businessManagers
      .filter((bm) => distinctBMIds.includes(bm.value))
      .map((bm) => ({ label: bm.label, value: bm.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [businessManagers, agents]);

  const allProductsOptions = useMemo(() => {
    return supplierProducts
      .map((prod) => ({ label: prod.label, value: prod.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [supplierProducts]);

  const allSupplierOptions = useMemo(() => {
    return suppliers
      .filter(
        (supp) =>
          supp.showAsSupplierInPs === true &&
          (isShowActiveSupplier
            ? supp.statusId === StatusEnum.ACTIVE
            : supp.statusId === StatusEnum.NOT_ACTIVE)
      )
      .map((supp) => ({ label: supp.label, value: Number(supp.entityId) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [suppliers, isShowActiveSupplier]);

  const groupByTypeOptions = useMemo(() => {
    return references
      .filter((ref) => ref.type === 'PS_GROUP_BY')
      .map((ref) => ({ label: ref.label, value: ref.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [references]);

  const supplierTypeOptions = [
    {
      value: SupplierTypeEnum.AIRLINE,
      label: 'Air',
      icon: <FlightTakeoffIcon />
    },
    {
      value: SupplierTypeEnum.LAND,
      label: 'Land',
      icon: <LocationCityIcon />
    }
  ];

  const [selectedHeaderGroups, setSelectedHeaderGroups] = useState<number[]>(
    headerGroup.defaultValues || []
  );
  const [selectedNetworks, setSelectedNetworks] = useState<number[]>(
    network.defaultValues || []
  );
  const [selectedSubGroups, setSelectedSubGroups] = useState<number[]>(
    subGroup.defaultValues || []
  );
  const [selectedStates, setSelectedStates] = useState<number[]>(
    state.defaultValues || []
  );
  const [selectedBusinessManagers, setSelectedBusinessManagers] = useState<number[]>(
    businessManager.defaultValues || []
  );

  const filteredNetworkOptions = useMemo(() => {
    const headerGroupsFilter =
      selectedHeaderGroups.includes(0) || selectedHeaderGroups.length === 0
        ? null
        : selectedHeaderGroups;
    if (headerGroupsFilter) {
      const headerGroupIdsSet = new Set(headerGroupsFilter.map((id) => id.toString()));
      return allNetworkOptions.filter((net) => {
        const ref = references.find((r) => r.value.toString() === net.value.toString());
        return ref && ref.parentReferenceId && headerGroupIdsSet.has(ref.parentReferenceId.toString());
      });
    } else {
      return allNetworkOptions;
    }
  }, [selectedHeaderGroups, allNetworkOptions, references]);

  const filteredSubGroupOptions = useMemo(() => {
    const networksFilter =
      selectedNetworks.includes(0) || selectedNetworks.length === 0
        ? filteredNetworkOptions.map(a => a.value)
        : selectedNetworks;
    if (networksFilter) {
      const networkIdsSet = new Set(networksFilter.map((id) => id.toString()));
      return allSubGroupOptions.filter((sub) => {
        const ref = references.find((r) => r.value.toString() === sub.value.toString());
        return ref && ref.parentReferenceId && networkIdsSet.has(ref.parentReferenceId.toString());
      });
    } else {
      return allSubGroupOptions;
    }
  }, [selectedHeaderGroups, selectedNetworks, allSubGroupOptions, references]);

  const filteredAgentOptions = useMemo(() => {
    const filterHeaderGroups =
      selectedHeaderGroups.includes(0) || selectedHeaderGroups.length === 0
        ? null
        : new Set(selectedHeaderGroups);
    const filterNetworks =
      selectedNetworks.includes(0) || selectedNetworks.length === 0
        ? null
        : new Set(selectedNetworks);
    const filterSubGroups =
      selectedSubGroups.includes(0) || selectedSubGroups.length === 0
        ? null
        : new Set(selectedSubGroups);
    const filterStates =
      selectedStates.includes(0) || selectedStates.length === 0
        ? null
        : new Set(selectedStates);
    const filterBms =
      selectedBusinessManagers.includes(0) || selectedBusinessManagers.length === 0
        ? null
        : new Set(selectedBusinessManagers);
    
    var agentsFilter = agents
      .filter((agent) => {
        const matchHeaderGroup =
          !filterHeaderGroups || (agent.headerGroupId && filterHeaderGroups.has(agent.headerGroupId));
        const matchNetwork =
          !filterNetworks || (agent.networkId && filterNetworks.has(agent.networkId));
        const matchSubGroup =
          !filterSubGroups || (agent.subGroupId && filterSubGroups.has(agent.subGroupId));
        const matchState = !filterStates || (agent.stateId && filterStates.has(agent.stateId));
        const matchBm = !filterBms || (agent.bmContactId && filterBms.has(agent.bmContactId));
        return matchHeaderGroup && matchNetwork && matchSubGroup && matchState && matchBm;
      })
      .map((a) => ({ label: a.network + ' - ' + a.name, value: parseInt(a.entityId, 10) }))
      .sort((a, b) => a.label.localeCompare(b.label));

      return agentsFilter
  }, [
    agents,
    selectedHeaderGroups,
    selectedNetworks,
    selectedSubGroups,
    selectedStates,
    selectedBusinessManagers
  ]);

  const handleHeaderGroupChange = (value) => { 
    const newSelected = value.map((v) => Number(v.value));
    setSelectedHeaderGroups(newSelected); // for filtering logic
    setValue('headerGroups', value); // update the form state
  };
  const handleNetworkChange = (value) => {
    setSelectedNetworks(value.map((v) => Number(v.value)));
  };
  const handleSubGroupChange = (value) => {
    setSelectedSubGroups(value.map((v) => Number(v.value)));
  };
  const handleStateChange = (value) => {
    setSelectedStates(value.map((v) => Number(v.value)));
  };
  const handleBusinessManagerChange = (value) => {
    setSelectedBusinessManagers(value.map((v) => Number(v.value)));
  };

  const checkOptions = () =>
    network.showField || state.showField || businessManager.showField;

  return (
    <>
      {checkOptions() && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
            </Grid>

            {agent.showField && (
              <Grid container spacing={2} marginBottom={5}>
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="agents"
                    label="Agent"
                    options={filteredAgentOptions}
                    addAllOption={agent.isAddAllOptions}
                    defaultValues={agent.defaultValues}
                  />
                </Grid>
              </Grid>
            )}

            {(supplier.showField || product.showField) && (
              <Grid container spacing={2} marginBottom={3}>
                {supplier.showField && (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <MultiSelectDropdown
                      name="supplier"
                      label="Supplier"
                      options={allSupplierOptions}
                      defaultValues={supplier.defaultValues}
                      addAllOption={supplier.isAddAllOptions}
                    />
                    {showActiveSupplierCheck && (
                      <CustomCheckbox
                        name="isSupplierActive"
                        label="Active"
                        defaultValues={isShowActiveSupplier}
                        handleChange={(value) => {
                          setIsShowActiveSupplier(value);
                        }}
                      />
                    )}
                    {showLandAndAirOption && (
                      <CustomRadioGroup
                        sx={{ marginLeft: 5 }}
                        name="supplierType"
                        options={supplierTypeOptions}
                        defaultValue={selectedSupplierType}
                        handleChange={(value) => {
                          setSelectedSupplierType(value);
                        }}
                      />
                    )}
                  </Grid>
                )}

                {product.showField && (
                  <Grid item xs={12} md={4} sm={12} lg={4}>
                    <MultiSelectDropdown
                      name="products"
                      label="Product"
                      options={allProductsOptions}
                      defaultValues={product.defaultValues}
                      addAllOption={product.isAddAllOptions}
                    />
                  </Grid>
                )}
                <Divider sx={{ margin: 3 }} />
              </Grid>
            )}

            <Grid container spacing={2}>
              {headerGroup.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="headerGroups"
                    label="Header Group"
                    options={headerGroupOptions}
                    addAllOption={headerGroup.isAddAllOptions}
                    defaultValues={headerGroup.defaultValues}
                    handleChange={handleHeaderGroupChange}
                  />
                </Grid>
              )}

              {network.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="networks"
                    label="Group"
                    options={filteredNetworkOptions}
                    defaultValues={network.defaultValues}
                    handleChange={handleNetworkChange}
                    addAllOption={network.isAddAllOptions}
                  />
                </Grid>
              )}

              {subGroup.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="subGroups"
                    label="Sub Group"
                    options={filteredSubGroupOptions}
                    defaultValues={subGroup.defaultValues}
                    handleChange={handleSubGroupChange}
                    addAllOption={subGroup.isAddAllOptions}
                  />
                </Grid>
              )}

              {state.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="states"
                    label="State"
                    options={allStateOptions}
                    defaultValues={state.defaultValues}
                    handleChange={handleStateChange}
                    addAllOption={state.isAddAllOptions}
                  />
                </Grid>
              )}

              {businessManager.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="businessManager"
                    label="Business Manager"
                    options={allBusinessManagerOptions}
                    defaultValues={businessManager.defaultValues}
                    handleChange={handleBusinessManagerChange}
                    addAllOption={businessManager.isAddAllOptions}
                  />
                </Grid>
              )}
            </Grid>

            {groupByType.showField && (
              <Grid container spacing={2} marginTop={5}>
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="groupByType"
                    label="Group By"
                    options={groupByTypeOptions}
                    addAllOption={groupByType.isAddAllOptions}
                    defaultValues={groupByType.defaultValue}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
