import React, { useMemo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

export const MultiSelectDropdown = ({
  name,
  label,
  options,
  addAllOption = true,
  defaultValues = null,
  handleChange = null
}) => {
  const { control } = useFormContext();

  const modifiedOptions = useMemo(() => {
    let modified = options;
    if (addAllOption) {
      let optionLabel = label;
      if (label.endsWith('y')) {
        optionLabel = label.slice(0, -1) + 'ies';
      } else if (!label.endsWith('s')) {
        optionLabel += 's';
      }
      modified = [{ value: 0, label: `All ${optionLabel}` }, ...options];
    }
    return modified;
  }, [options, addAllOption, label]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]} // Start empty
      render={({ field, fieldState }) => {
        useEffect(() => {
          // Once options are loaded, set the default selection
          if ((defaultValues === null || defaultValues.length === 0) && addAllOption && modifiedOptions.length > 0) {
            const allOption = modifiedOptions.find(opt => opt.value === 0);
            if (allOption && (!field.value || field.value.length === 0)) {
              field.onChange([allOption]);
            }
          } else if (defaultValues && defaultValues.length > 0 && modifiedOptions.length > 0) {
            const initialSelection = modifiedOptions.filter(opt => defaultValues.includes(opt.value));
            if (initialSelection.length && (!field.value || field.value.length === 0)) {
              field.onChange(initialSelection);
            }
          }
        }, [modifiedOptions, defaultValues, addAllOption, field]);

        const isAllSelected = field.value?.some((v) => v.value === 0);

        const handleAllOption = (newValue) => {
          if (isAllSelected) {
            // If currently "All" is selected and user picks something else, remove "All"
            if (newValue.some((item) => item.value !== 0)) {
              return newValue.filter((item) => item.value !== 0);
            }
          } else {
            // If "All" not selected and user selects it, remove others
            if (newValue.some((item) => item.value === 0)) {
              return newValue.filter((item) => item.value === 0);
            }
          }
          return newValue;
        };

        return (
          <Autocomplete
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            id={name}
            options={modifiedOptions}
            value={field.value || []}
            onChange={(event, newValue, reason, details) => {
              if (addAllOption) {
                newValue = handleAllOption(newValue);
              }
              field.onChange(newValue);
              if (handleChange) {
                handleChange(newValue, reason, details);
              }
            }}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
          />
        );
      }}
    />
  );
};
