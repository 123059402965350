import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, Grid, Alert, CircularProgress } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { makeStyles } from '@mui/styles';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/LogoSign';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: 'red',
    marginTop: '8px',
  },
  textFieldError: {
    animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
}));

interface CodeVerificationProps {
  isEmailVerification: boolean;
  userId: string;
  username: string;
}

const CodeVerification: React.FC<CodeVerificationProps> = ({ isEmailVerification, userId, username }) => {
  const classes = useStyles();
  const { verify } = useAuth() as any;
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(60 * 2); // 2 minutes in seconds
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.toUpperCase(); // Convert to uppercase
    if (input.length <= 6) { // Limit to 6 characters
      setCode(input);
    }
    if (error) setError(''); // Reset error on input change
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleVerify();
    }
  };

  const handleVerify = async () => {
    setLoading(true); // Show loading spinner
    try {
      await verify(code, userId, username);
      // Handle successful verification here, if needed
    } catch (error) {
      if(error === 'Permissions not set for this User!! Please Contact IT Support')
        setError(error);
      else
        setError('Invalid Code. Please try again.'); // Set custom or dynamic error message here
      setLoading(false); // Revert to show the Verify button again
    }
  };

  const handleResendCode = () => {
    // Implement resend logic
    setTimer(60 * 2); // Reset timer
  };

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <Logo />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Paper elevation={3} style={{ padding: '32px', width: '100%', maxWidth: '500px' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography marginBottom={3} variant="h5" gutterBottom>
              {isEmailVerification ? 'VERIFY VIA EMAIL ADDRESS' : 'VERIFY VIA AUTHENTICATOR APP'}
            </Typography>

            <Typography marginBottom={5} variant="subtitle1" gutterBottom>
              {isEmailVerification
                ? `Please check your inbox and enter the verification code below to verify your email address. The code will expire in ${formatTime(timer)}.`
                : 'Please check your Authenticator App and enter the verification code below to verify'}
            </Typography>

            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Verification Code"
                  value={code}
                  onChange={handleCodeChange}
                  onKeyDown={handleKeyDown} // Listening for key down event
                  inputProps={{ maxLength: 6 }}
                  style={{ textAlign: 'center' }}
                  error={Boolean(error)}
                  className={error ? classes.textFieldError : ''}
                />
                {error && <Alert severity="error" className={classes.errorText}>{error}</Alert>}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleVerify} disabled={!code || timer <= 0} fullWidth>
                    Verify
                  </Button>
                )}
              </Grid>
              {isEmailVerification && (
                <Grid item xs={12} container justifyContent="center">
                  <Button startIcon={<RestartAltIcon />} onClick={handleResendCode} disabled={timer > 0}>
                    Resend code
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default CodeVerification;
