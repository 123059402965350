import React from 'react';
import { useEffect, useMemo } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getBusinessManagers } from 'src/ems/slices/businessManager';
import { getAgents } from 'src/ems/slices/agent';
import { getSuppliers } from 'src/ems/slices/supplier';
import { useDispatch, useSelector } from 'src/store';
import { useFormContext } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import CustomToggleSwitch from 'src/ems/components/rhf-components/ToggleSwitch';
import Dropdown from 'src/ems/components/rhf-components/Dropdown';
import FormDropDownProps from 'src/ems/models/form_dropdown_props';
import { OptionPanelProps } from '../Models/ReportFilterPanelProps';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { StatusEnum, SupplierTypeEnum } from 'src/ems/enums/general.enums';

export const OptionPanel: React.FC<OptionPanelProps> = ({
  businessManager,
  salesType,
  airline,
  departureOrigin,
  destinationAirport,
  originAirport,
  destinationCountry,
  destinationRegion,
  journeyType,
  originCountry,
  paxType,
  ticketApplication,
  travelClass,
  travelType,
  revenueType,
  yoyType,
  includeEDMandWholeSaleTickets,
  includeMixedCarriers,
  includeReissueTickets,
  includeMargin,
  includePax,
  includePaymentReport,
  includeShowYoy,
  includeShowWow,
  showFareBasis
}) => {
  const { control } = useFormContext();

  const dispatch = useDispatch();

  const { references } = useSelector((state) => state.referenceDropDown);
  const { businessManagers } = useSelector((state) => state.businessManager);
  const { suppliers } = useSelector((state) => state.supplier);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getSuppliers(signal));
    dispatch(getBusinessManagers(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  //Getting Business Managers Options
  const businessManagerOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      businessManagers
        .map((bm) => ({ label: bm.label, value: bm.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [businessManagers]
  );

  //Getting Airline Options
  const airlineOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      suppliers
        .filter(
          (supplier) =>
            supplier.supplierCode !== null &&
            supplier.statusId === StatusEnum.ACTIVE &&
            supplier.supplierType === 'AIRLINE'
        )
        .map((supplier) => ({
          label: supplier.name,
          value: parseInt(supplier.entityId, 10)
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [suppliers]
  );

  // Getting Options from Reference
  const tktAppOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_APP')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const departureOriginOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'DORIGN')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const destinationAirportsOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_DEST')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const originAirportsOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_DEST')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const destinationRegionOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_REGN')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const destinationCountryOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_CTRY')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const originCountryOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference) => reference.type == 'TKT_CTRY')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const journeyTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'JTYPE')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const travelClassOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'TCLASS')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const paxTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'PTYPE')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const travelTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'TTYPE')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const salesTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'SALOPT2')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const revenueTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'REVOPT')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const yoyTypeOptions: MultiSelectDropDownOptionsProps[] = useMemo(
    () =>
      references
        .filter((reference: FormDropDownProps) => reference.type == 'YOYTYPE')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [references]
  );

  const checkOptions = () => {
    if (
      airline.showField ||
      departureOrigin.showField ||
      destinationAirport.showField ||
      destinationCountry.showField ||
      destinationRegion.showField ||
      journeyType.showField ||
      originCountry.showField ||
      paxType.showField ||
      ticketApplication.showField ||
      travelClass.showField ||
      travelType.showField ||
      salesType.showField ||
      businessManager.showField ||
      revenueType.showField ||
      yoyType.showField
    )
      return true;
    else return false;
  };

  const checkToggleOptions = () => {
    if (
      includeEDMandWholeSaleTickets.showField ||
      includeMixedCarriers.showField ||
      includeReissueTickets.showField ||
      includeMargin.showField ||
      includePax.showField ||
      includePaymentReport.showField ||
      includeShowYoy.showField ||
      showFareBasis.showField
    )
      return true;
    else false;
  };

  return (
    <>
      {/* Option Panel */}
      {checkOptions() && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container spacing={5}>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">Option Panel</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {businessManager.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="businessManager"
                    label="Business Manager"
                    options={businessManagerOptions}
                    addAllOption={businessManager.isAddAllOptions}
                    defaultValues={businessManager.defaultValues}
                  />
                </Grid>
              )}
              {salesTypeOptions.length > 0 && salesType.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="salesType"
                    label="Sales Type"
                    options={salesTypeOptions}
                    addAllOption={salesType.isAddAllOptions}
                    defaultValues={salesType.defaultValue}
                  />
                </Grid>
              ) : null}
              {ticketApplication.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="ticketApplication"
                    label="Ticket Application"
                    options={tktAppOptions}
                    addAllOption={ticketApplication.isAddAllOptions}
                    defaultValues={ticketApplication.defaultValues}
                  />
                </Grid>
              )}
              {departureOriginOptions.length > 0 &&
              departureOrigin.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="departureOfOrigin"
                    label="Departure Origin"
                    options={departureOriginOptions}
                    addAllOption={departureOrigin.isAddAllOptions}
                    defaultValues={departureOrigin.defaultValue}
                  />
                </Grid>
              ) : null}
              {airline.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="airline"
                    label="Airline"
                    options={airlineOptions}
                    addAllOption={airline.isAddAllOptions}
                    defaultValues={airline.defaultValues}
                  />
                </Grid>
              )}
              {destinationAirport.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="destinationAirport"
                    label="Destination Airport"
                    options={destinationAirportsOptions}
                    addAllOption={destinationAirport.isAddAllOptions}
                    defaultValues={destinationAirport.defaultValues}
                  />
                </Grid>
              )}
              {originAirport.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="originAirport"
                    label="Origin Airport"
                    options={originAirportsOptions}
                    addAllOption={originAirport.isAddAllOptions}
                    defaultValues={originAirport.defaultValues}
                  />
                </Grid>
              )}
              {destinationRegion.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="destinationRegion"
                    label="Destination Region"
                    options={destinationRegionOptions}
                    addAllOption={destinationRegion.isAddAllOptions}
                    defaultValues={destinationRegion.defaultValues}
                  />
                </Grid>
              )}
              {destinationCountry.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="destinationCountry"
                    label="Destination Country"
                    options={destinationCountryOptions}
                    addAllOption={destinationCountry.isAddAllOptions}
                    defaultValues={destinationCountry.defaultValues}
                  />
                </Grid>
              )}
              {originCountry.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="originCountry"
                    label="Origin Country"
                    options={originCountryOptions}
                    addAllOption={originCountry.isAddAllOptions}
                    defaultValues={originCountry.defaultValues}
                  />
                </Grid>
              )}
              {journeyType.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="journeyType"
                    label="Journey Type"
                    options={journeyTypeOptions}
                    addAllOption={journeyType.isAddAllOptions}
                    defaultValues={journeyType.defaultValue}
                  />
                </Grid>
              )}
              {travelClass.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="travelClass"
                    label="Travel Class"
                    options={travelClassOptions}
                    addAllOption={travelClass.isAddAllOptions}
                    defaultValues={travelClass.defaultValue}
                  />
                </Grid>
              )}
              {paxType.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="paxType"
                    label="Pax Type"
                    options={paxTypeOptions}
                    addAllOption={paxType.isAddAllOptions}
                    defaultValues={paxType.defaultValue}
                  />
                </Grid>
              )}
              {travelType.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="travelType"
                    label="Travel Type"
                    options={travelTypeOptions}
                    addAllOption={travelType.isAddAllOptions}
                    defaultValues={travelType.defaultValue}
                  />
                </Grid>
              )}
              {revenueTypeOptions.length > 0 && revenueType.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="revenueType"
                    label="Revenue Type"
                    options={revenueTypeOptions}
                    addAllOption={revenueType.isAddAllOptions}
                    defaultValues={revenueType.defaultValue}
                  />
                </Grid>
              ) : null}
              {yoyType.showField && (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Dropdown
                    name="yoyType"
                    label="Yoy Type"
                    options={yoyTypeOptions}
                    addAllOption={yoyType.isAddAllOptions}
                    defaultValues={yoyType.defaultValue}
                  />
                </Grid>
              )}
            </Grid>
            {checkToggleOptions() && (
              <div style={{ marginTop: '20px' }}>
                <Grid container>
                  {includeMixedCarriers.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeMixedCarriers"
                        label="Include Mixed Carriers"
                        defaultValue={includeMixedCarriers.defaultValue}
                      />
                    </Grid>
                  )}
                  {includeEDMandWholeSaleTickets.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeEDMandWholesaleTickets"
                        label="Include EMD And Wholesale Tickets"
                        defaultValue={
                          includeEDMandWholeSaleTickets.defaultValue
                        }
                      />
                    </Grid>
                  )}
                  {includeReissueTickets.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeReissueTickets"
                        label="Include Re-isuue Tickets"
                        defaultValue={includeReissueTickets.defaultValue}
                      />
                    </Grid>
                  )}
                  {includePax.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includePax"
                        label="Include Pax"
                        defaultValue={includePax.defaultValue}
                      />
                    </Grid>
                  )}
                  {includeShowYoy.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeShowYoy"
                        label="Include Show YOY"
                        defaultValue={includeShowYoy.defaultValue}
                      />
                    </Grid>
                  )}
                  {includeShowWow.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeShowWow"
                        label="Include ShowWow"
                        defaultValue={includeShowWow.defaultValue}
                      />
                    </Grid>
                  )}
                  {includePaymentReport.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includePaymentReport"
                        label="Exclude EMD"
                        defaultValue={includePaymentReport.defaultValue}
                      />
                    </Grid>
                  )}
                  {includeMargin.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="includeMargin"
                        label="Include Margin"
                        defaultValue={includeMargin.defaultValue}
                      />
                    </Grid>
                  )}
                  {showFareBasis.showField && (
                    <Grid item xs={6} md={3} sm={6} lg={3}>
                      <CustomToggleSwitch
                        name="showFareBasis"
                        label="Show Fare Basis"
                        defaultValue={showFareBasis.defaultValue}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
