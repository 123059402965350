import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// EMS Dashboards

const SalesDashboard = Loader(lazy(() => import('src/ems/components/dashboards/SalesDashboard')));
const ManagementDashboard = Loader(lazy(() => import('src/ems/components/dashboards/ManagementDashboard')));
const ProductDashboard = Loader(lazy(() => import('src/ems/components/dashboards/ProductDashboard')));
const HighlightsDashboard = Loader(lazy(() => import('src/ems/components/dashboards/HighlightsDashboard')));
const TurnaroundDashboard = Loader(lazy(() => import('src/ems/components/dashboards/TurnaroundDashboard')));

const ems_dashboardsRoutes = [
  {
    path: '',
    element: <Navigate to="salesDashboard" replace />
  },
  {
    path: 'sales_dashboard',
    element: <SalesDashboard />
  },
  {
    path: 'management_dashboard',
    element: <ManagementDashboard />
  },
  {
    path: 'product_dashboard',
    element: <ProductDashboard />
  },
  {
    path: 'highlights_dashboard',
    element: <HighlightsDashboard />
  },
  {
    path: 'turnaround_dashboard',
    element: <TurnaroundDashboard />
  },
];

export default ems_dashboardsRoutes;
