import ProductLandSalesSummary from 'src/ems/models/product_land_sales_summary';
import http from './http.service';
import ProductLandSalesFileImportData from 'src/ems/models/product_land_sales_file_import_data';

const apiEndpoint = '/product/land/data';

export async function getProductLandSalesDataList(fileType) {
  const response = await http.get<ProductLandSalesSummary[]>(apiEndpoint + '/' + fileType);
  return response;
}

export async function getProductLandSalesDetail(fileImportId: string, fileType: string = "normal") {
  const response = await http.get<ProductLandSalesFileImportData[]>(apiEndpoint + '/' + fileType + '/detail/' + fileImportId);
  return response;
}

export async function update(data, fileType: string = "normal") {
  await http.put(apiEndpoint + '/' + fileType + '/update', data );
}

export async function reverseFile(fileType, fileId) {
  const response = await http.get(apiEndpoint + '/reversefile/' + fileType + '/' + fileId);
  return response;
}

export default {
  getProductLandSalesDataList,
  getProductLandSalesDetail,
  update,
  reverseFile
};
