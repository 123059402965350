import { ReportFilterPanelProps, defaultReportFilterPanelProps } from "src/ems/components/common/ReportFilterPanel/Models/ReportFilterPanelProps";
import { finance_details_report_columns } from "src/ems/models/agent_report_models/finance_details_report_columns";
import report_parameters from "src/ems/models/report_models/report_parameters";
import { applyCommonProperties } from 'src/ems/services/report.general.service';

export function getReportFilterPanel (defaultValues: report_parameters): ReportFilterPanelProps {
  let preferenceData: report_parameters = {};
  // const defaultValues: report_parameters = await getPreference(role.RPT_DETAILED_TICKET_REPORT, isDefault);

  if (defaultValues !== null) {
      preferenceData = defaultValues;
  }

  const reportFilterPanel: ReportFilterPanelProps = defaultReportFilterPanelProps();

    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 7)

    applyCommonProperties(reportFilterPanel.agentPanel, 'headerGroup', preferenceData.headerGroup);
    applyCommonProperties(reportFilterPanel.agentPanel, 'agent', preferenceData.agent);
    applyCommonProperties(reportFilterPanel.agentPanel, 'network', preferenceData.network);
    applyCommonProperties(reportFilterPanel.agentPanel, 'status', preferenceData.status);
    
    return reportFilterPanel;
  }

  export function getMapping(data: any, columnPrefrence: finance_details_report_columns) {
    if (data.length > 0) {
        const firstObject = data[0];
        const mapping = {};
  
        for (const key in firstObject) {
          if (firstObject.hasOwnProperty(key)) {
            if(key === 'entityId') {
              mapping[key] = { visible: false };
            }
            else if (key === 'lastTrustBankUpdated' || key === 'lastGeneralBankUpdated') {
              mapping[key] = { type: 'date string', visible: columnPrefrence[key] };
            } 
            else if (typeof firstObject[key] === 'string') {
              mapping[key] = { type: 'string', visible: columnPrefrence[key] };
            } 
            else if (typeof firstObject[key] === 'number') {
              mapping[key] = { type: 'number', visible: columnPrefrence[key] };
            } 
            else if (firstObject[key] instanceof Date) {
              mapping[key] = { type: 'date', visible: columnPrefrence[key] };
            } 
            else {
              mapping[key] = { type: 'string', visible: columnPrefrence[key] };
            }
          }
        }
  
        return mapping;
      }
      return {};
}



