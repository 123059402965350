export interface finance_details_report_columns {
  entityId: boolean;
  agentName: boolean;
  agentNumber: boolean;
  group: boolean;
  headerGroup: boolean;
  status: boolean;
  TrustAccountBankName: boolean;
  TrustAccountBSB: boolean;
  TrustAccountNumber: boolean;
  LastTrustBankUpdated: boolean;
  GeneralAccountBankName: boolean;
  GeneralAccountBSB: boolean;
  GeneralAccountNumber: boolean;
  LastGeneralBankUpdated: boolean;
}

export function defaultFinanceDetailsReportColumns(): finance_details_report_columns {
  return {
    entityId: true,
    agentName: true,
    agentNumber: true,
    group: true,
    headerGroup: true,
    status: true,
    TrustAccountBankName: true,
    TrustAccountBSB: true,
    TrustAccountNumber: true,
    LastTrustBankUpdated: true,
    GeneralAccountBankName: true,
    GeneralAccountBSB: true,
    GeneralAccountNumber: true,
    LastGeneralBankUpdated: true,
  };
}
